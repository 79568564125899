import { httpAxios } from "../../helper/httpHelper";

export async function reqForState(user, state) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/state-update/request`,
      { state },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function fetchPendingStateRequests(user, page, limit, query) {
  try {
    let url = `/retailer/state-update/request/list?page=${page}&limit=${limit}`;

    if (query) {
      url += `&search=${query}`;
    }

    const { data } = await httpAxios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function fetchAcceptedReports(user, page, limit, searchQuery) {
  try {
    let url = `/retailer/state-update/request/report?page=${page}&limit=${limit}`;

    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }

    const { data } = await httpAxios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function acceptState(user, id) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/state-update/request/accept`,
      { id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function rejectState(user, id) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/state-update/request/reject`,
      { id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getRetailerActivities(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/retailer/metrics`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getDonutChartData(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/retailer/commission/statistics`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getAvgBalanceData(user, year) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/average-balance/graph/get?year=${year}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getLineChartData(user, year) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/dashboard/retailer/commission/analytics?year=${year}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}
