import React from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { X, CheckCircle } from "lucide-react";
import { useAppContext } from "../../../context/AppContext";
import { useQuery } from "react-query";
import { getUser } from "../../../services/auth";
import { getEligibleSlabs } from "../../../services/dashboardServices";

// Months list used to show all months in a year
const monthsList = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const SlabModal = () => {
  const { setModal } = useAppContext();

  const { data: eligibleSlabs, isLoading } = useQuery({
    queryKey: ["eligibleSlabs"],
    queryFn: async () => {
      const auth = await getUser();
      const res = await getEligibleSlabs(auth);
      return res.data;
    },
    staleTime: 1000 * 60 * 5,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  return (
    <ModalLayout maxWidth="max-w-xl">
      <div>
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h1 className="text-lg font-semibold tracking-wide">
            Eligible Slab Details
          </h1>
          <button
            onClick={() => setModal("")}
            className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
          >
            <X />
          </button>
        </div>

        {/* Important Note Section */}
        <div className="p-4 bg-yellow-100 border-l-4 border-yellow-400 text-sm text-yellow-700">
          <p className="font-semibold">Important Notes:</p>
          <ul className="list-disc ml-4 space-y-1">
            <li>
              If a user achieves <strong>9 months</strong> in a year, they will
              receive a <strong>special benefit</strong>.
            </li>
            <li>
              A user can only achieve <strong>one slab per month</strong>.
              Multiple slabs in the same month are not allowed.
            </li>
            <li>
              If you achieve any slab by adding the required number of{" "}
              <strong>sub-retailers</strong> in a month, the corresponding slab
              benefits will become active from the{" "}
              <strong>1st date of the next month</strong>.
            </li>
          </ul>
        </div>

        {/* Loader */}
        {isLoading ? (
          <div className="flex justify-center items-center py-6">
            Loading...
          </div>
        ) : (
          <div className="p-4 space-y-6 h-full max-h-[32rem] overflow-y-auto">
            {eligibleSlabs && eligibleSlabs.length > 0 ? (
              eligibleSlabs.map((slab, index) => (
                <div
                  key={slab._id}
                  className={`relative pb-6 ${
                    index !== eligibleSlabs.length - 1
                      ? "border-b border-gray-200"
                      : ""
                  }`}
                >
                  {/* Step Indicator */}
                  <div className="flex items-center space-x-3">
                    <div
                      className={`w-10 h-10 flex items-center justify-center rounded-full border-2 transition-all duration-300 ${
                        slab.isAchieved
                          ? "border-emerald-500 bg-emerald-100"
                          : "border-gray-300 bg-white"
                      }`}
                    >
                      {slab.isAchieved ? (
                        <CheckCircle className="text-emerald-500 w-6 h-6" />
                      ) : (
                        <span className="text-gray-500 font-semibold">
                          {index + 1}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <h2 className="text-lg font-medium">Slab {index + 1}</h2>
                      <span
                        className={`text-xs font-semibold px-2 py-0.5 rounded-full ${
                          slab.isAchieved
                            ? "bg-emerald-100 text-emerald-700"
                            : "bg-gray-100 text-gray-500"
                        }`}
                      >
                        {slab.isAchieved ? "Active" : "Not Active"}
                      </span>
                    </div>
                  </div>

                  <p className="text-gray-600 mt-2">
                    Add <strong>{slab.count}</strong> Sub-Retailers to achieve
                    this slab.
                  </p>
                  <p className="text-lg font-semibold text-purple-600">
                    Benefit: {slab.benefitPercentage}%
                  </p>

                  {/* Months Achieved */}
                  <div className="flex flex-wrap gap-2 mt-4">
                    {monthsList.map((month) => (
                      <button
                        key={month}
                        className={`p-1 px-2 rounded-md font-semibold transition-all duration-300 text-sm ${
                          slab.achievedMonths?.includes(month)
                            ? "bg-lime-500 text-white"
                            : "bg-gray-100 text-gray-600"
                        }`}
                        disabled={slab.achievedMonths?.includes(month)}
                      >
                        {month}
                      </button>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No slabs available</p>
            )}
          </div>
        )}

        {/* Close Button */}
        <div className="px-4 pb-4 w-full">
          <button
            onClick={() => setModal("")}
            className="w-full bg-red-500 hover:bg-red-600 py-2 rounded-xl text-white font-semibold tracking-wider"
          >
            Close
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default SlabModal;
