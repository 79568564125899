import React, { useEffect, useState } from "react";
import { Pencil, PencilLine } from "lucide-react";
import { Switch } from "@headlessui/react";
import {
  disableOfferBenefit,
  enableOfferBenefit,
} from "../../services/manageOfferServices";
import { toast } from "react-toastify";
import { getUser } from "../../services/auth";
import { disableAds, enableAds } from "../../services/manageAdsServices";
import { useAppContext } from "../../context/AppContext";

const AdManageTable = ({
  manageAds,
  currentPage,
  limit,
  triggerFetch,
  setEdit,
  selectedId,
  setSelectedId,
}) => {
  // console.log("manageAds", manageAds?.data?.docs);

  const handleEdit = (ads) => {
    setSelectedId(ads);
    setEdit("edit");
  };

  // For enable and disable offers benefits
  const handleAction = async (action, id) => {
    try {
      const user = await getUser();
      const res = action
        ? await disableAds(user, id)
        : await enableAds(user, id);

      if (res.status) {
        toast.success(res.message);
        triggerFetch();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="overflow-x-auto overflow-y-hidden">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border border-custom-border">
            <table className="min-w-full">
              <thead className="bg-sub-card border-b border-custom-border bg-gradient-to-tr from-custom-border to-bg-color text-white">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Serial No.
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Ad Title
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Remaining Time
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Package
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Action
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {manageAds?.data?.docs?.map((ads, id) => (
                  <tr
                    key={id}
                    className={`${
                      id % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                    } ${selectedId?.id === ads?.id ? "bg-black/10" : ""}`}
                  >
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {id + 1 + (currentPage - 1) * limit}.
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {ads.title}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {ads.endDate && <RemainingTime endDate={ads.endDate} />}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      {ads.months} Months
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      <button onClick={() => handleEdit(ads)}>
                        <PencilLine
                          fill={selectedId?.id === ads?.id ? "green" : "none"} // Use 'currentColor' for better CSS control
                          className={`w-4 h-4 ${
                            selectedId?.id === ads?.id
                              ? "text-green-400"
                              : "text-emerald-500"
                          }`}
                        />
                      </button>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm">
                      <Switch
                        checked={ads.isActive}
                        onChange={() => handleAction(ads?.isActive, ads?.id)}
                        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-custom-border"
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdManageTable;

const calculateRemainingTime = (endDate) => {
  const now = new Date();
  const end = new Date(endDate);
  const remainingTime = end - now; // Difference in milliseconds

  // Convert to hours, minutes, seconds
  const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
  const seconds = Math.floor((remainingTime / 1000) % 60);

  return {
    hours: hours.toString().padStart(2, "0"),
    minutes: minutes.toString().padStart(2, "0"),
    seconds: seconds.toString().padStart(2, "0"),
    isExpired: remainingTime <= 0, // Check if time is up
  };
};

const RemainingTime = ({ endDate }) => {
  const [time, setTime] = useState(calculateRemainingTime(endDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(calculateRemainingTime(endDate)); // Update remaining time
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [endDate]); // Add endDate as a dependency to update timer when it changes

  return (
    <span>
      {time.isExpired
        ? "Expired"
        : `${time.hours}:${time.minutes}:${time.seconds}`}
    </span>
  );
};
