import React, { useState, useRef } from "react";
import { Stage, Layer, Image as KonvaImage, Text } from "react-konva";
import useImage from "use-image";

const KonvaEditor = () => {
  const hardcodedImageUrl = "/images/template.png"; // Change this to your image path
  const [background] = useImage(hardcodedImageUrl);

  const [texts, setTexts] = useState([
    { id: 1, text: "10% Extra", x: 50, y: 50 },
    { id: 2, text: "Bonus 25%", x: 150, y: 100 },
  ]);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImagePos, setUploadedImagePos] = useState({ x: 200, y: 200 });
  const [uploadedImg] = useImage(uploadedImage);

  const stageRef = useRef();

  // Add a new text element
  const addText = () => {
    const newText = {
      id: texts.length + 1,
      text: "New Text",
      x: 100,
      y: 150,
    };
    setTexts([...texts, newText]);
  };

  // Update text position when dragged
  const handleTextDrag = (id, newX, newY) => {
    setTexts((prevTexts) =>
      prevTexts.map((t) => (t.id === id ? { ...t, x: newX, y: newY } : t))
    );
  };

  // Delete a text element
  const deleteText = (id) => {
    setTexts((prevTexts) => prevTexts.filter((t) => t.id !== id));
  };

  // Handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Save Data (Text & Image Positions)
  const handleSave = () => {
    const data = {
      texts: texts.map(({ id, ...rest }) => rest),
      uploadedImage: uploadedImage,
      uploadedImagePos: uploadedImage
        ? { x: uploadedImagePos.x, y: uploadedImagePos.y }
        : null,
    };

    console.log("Saved Data:", data);
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      {/* Upload Image */}
      <input type="file" onChange={handleImageUpload} className="border p-2 rounded" />

      {/* Add Text Button */}
      <button onClick={addText} className="bg-green-500 text-white px-4 py-2 rounded">
        Add Text
      </button>

      {/* Image Editing Canvas */}
      <div className="border  relative">
        <Stage ref={stageRef} width={500} height={300}>
          <Layer>
            {/* Background Image */}
            {background && <KonvaImage image={background} width={470} height={225} />}

            {/* Draggable Text Elements */}
            {texts.map((t) => (
              <Text
                key={t.id}
                draggable
                text={t.text}
                fontSize={20}
                fill="white"
                x={t.x}
                y={t.y}
                onDragEnd={(e) => handleTextDrag(t.id, e.target.x(), e.target.y())}
                onDblClick={() => deleteText(t.id)}
              />
            ))}

            {/* Uploaded Image (if exists) */}
            {uploadedImg && (
              <KonvaImage
                image={uploadedImg}
                x={uploadedImagePos.x}
                y={uploadedImagePos.y}
                draggable
                onDragEnd={(e) =>
                  setUploadedImagePos({ x: e.target.x(), y: e.target.y() })
                }
              />
            )}
          </Layer>
        </Stage>
      </div>

      {/* Save Button */}
      <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">
        Save Changes
      </button>
    </div>
  );
};

export default KonvaEditor;
