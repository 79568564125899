import { ArrowDownNarrowWide, ArrowUpNarrowWide, CloudDownload, LeafyGreenIcon, RefreshCcw, Search } from "lucide-react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getUser } from "../../../services/auth";
import { formatDate } from "../../../utils/formatTime";
import Pagination from "../../Pagination";
import { exportStatesTxnCSV, getAllStateTxnsList } from "../../../services/state/stateServices";
import { toast } from "react-toastify";

const StateTransactionTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const limit = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleExportTxns = async () => {
    try {
      const user = await getUser();
      const csvData = await exportStatesTxnCSV(user);

      const blob = new Blob([csvData], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute("download", "StateMemeberTransactions.csv");

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error(error);
      toast.error("Failed to export CSV.");
    }
  };

  const { data: stateTxnTableData, isLoading } = useQuery({
    queryKey: ["stateTxnTableData", currentPage, limit, searchQuery, selectedDate],
    queryFn: async () => {
      const user = await getUser();
      const res = await getAllStateTxnsList(
        user,
        currentPage,
        limit,
        selectedDate,
        searchQuery
      );
      return res.data;
    },
    onSuccess: (data) => {
      if (data) {
        console.log(data);
        setTotalPages(data.totalPages || 1);
      } else {
        console.error("Couldn't fetch stateCommission");
      }
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

    // For acc and decc filter
    const getValue = (obj, key) => {
      return key.split(".").reduce((o, k) => (o ? o[k] : ""), obj);
    };
  
    const sortedData = [...(stateTxnTableData?.docs || [])].sort((a, b) => {
      if (sortConfig.key) {
        const aValue = getValue(a, sortConfig.key) || "";
        const bValue = getValue(b, sortConfig.key) || "";
  
        if (sortConfig.direction === "asc") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      }
      return 0;
    });
  
    const handleSort = (key) => {
      setSortConfig((prev) => ({
        key,
        direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
      }));
    };

  return (
    <>
      <div className="pb-4">
        <h1 className="sm:text-lg font-semibold text-center sm:text-left">
          Overall Transactions Table
        </h1>
        <p className="text-sm text-gray-700">
          A detailed view of all transactions recorded.
        </p>
      </div>
      <div className="pb-4 flex flex-col sm:flex-row justify-between sm:items-center gap-2 w-full">
        <div className="border p-2 rounded-xl flex items-center gap-2 h-10">
          <Search className="w-5 h-5" />
          <input
            value={searchQuery}
            onChange={handleSearchChange}
            type="search"
            placeholder="Search..."
            className="w-full outline-none"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 justify-end items-end lg:max-w-[40rem] xl:max-w-[44rem] sm:gap-2 text-sm space-y-2 sm:space-y-0">
          <div className="flex gap-1 border p-1 rounded-xl w-full xl:w-56 h-10">
            <input
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              type="date"
              title="search"
              name="Date"
              placeholder="Search"
              className="outline-none w-full"
            />
          </div>
          <div className="flex gap-1 border p-1 rounded-xl w-full col-span-2 sm:col-span-1 h-10">
            <button
              disabled={stateTxnTableData?.docs?.length === 0}
              onClick={handleExportTxns}
              className="h-full flex items-center justify-center gap-1 bg-bg-color disabled:bg-bg-color/50 disabled:cursor-not-allowed w-full rounded-lg px-2 text-white"
            >
              <CloudDownload style={{ width: "16px" }} /> <span>|</span>CSV
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="min-h-96 w-full flex flex-col gap-2 justify-center items-center font-suse">
          <RefreshCcw className="w-6 h-6 animate-spin" />
          <p>Loading transactions...</p>
        </div>
      ) : stateTxnTableData?.docs?.length > 0 ? (
        <div className="overflow-x-auto ">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  No
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Transaction Id
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Transaction Type
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                Particular
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  State
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Pincode
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  From (Name)
                </th>
                <th
                  className="py-2 px-4 border-b whitespace-nowrap text-left flex items-center gap-1"
                  onClick={() => handleSort("user.referralCode")}
                >
                  From (Member ID)
                  {/* {sortConfig.direction === "asc" ? (
                    <ArrowUpNarrowWide className="w-4 h-4 text-gray-500" />
                  ) : (
                    <ArrowDownNarrowWide className="w-4 h-4 text-gray-500" />
                  )} */}
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  To (Name)
                </th>
                <th
                  className="py-2 px-4 border-b whitespace-nowrap text-left cursor-pointer flex items-center justify-between gap-1 hover:bg-blue-50 rounded-t-xl"
                  onClick={() => handleSort("retailer.referralCode")}
                >
                  To (Member ID){" "}
                  {sortConfig.direction === "asc" ? (
                    <ArrowUpNarrowWide className="w-4 h-4 text-gray-500" />
                  ) : (
                    <ArrowDownNarrowWide className="w-4 h-4 text-gray-500" />
                  )}
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Order Date
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Status
                </th>
                <th className="py-2 px-4 border-b whitespace-nowrap text-left">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((state, id) => (
                <tr
                  key={id}
                  className="text-sm"
                  // className={id % 2 === 0 ? "border" : "bg-bg-color/10"}
                >
                  <td className="p-4 border-b whitespace-nowrap">
                    {id + 1 + (currentPage - 1) * limit}.
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.transactionId ?? "-"}
                  </td>
                  <td
                    className={`p-4 border-b whitespace-nowrap font-semibold italic ${
                      state?.transactionType === "CREDIT" ? "text-emerald-500" : "text-red-500"
                    }`}
                  >
                    {state?.transactionType ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.type ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.state ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.pincode ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.user?.name ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.user?.referralCode ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.retailer?.name ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.retailer?.referralCode ?? "-"}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {formatDate(state?.createdAt)}
                  </td>
                  <td
                    className={`p-4 border-b whitespace-nowrap italic font-semibold ${
                      state?.status === "COMPLETED"
                        ? "text-green-500"
                        : state?.status === "PENDING"
                        ? "text-yellow-500"
                        : "text-red-500"
                    }`}
                  >
                    {state?.status}
                  </td>
                  <td className="p-4 border-b whitespace-nowrap">
                    {state?.fundAmount?.toLocaleString() ?? "0.00"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="min-h-96 w-full flex flex-col justify-center items-center font-suse">
          <LeafyGreenIcon className="w-6 h-6" />
          <p>Transactions not found.</p>
        </div>
      )}

      {totalPages > 1 && (
        <div className="mt-6">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default StateTransactionTable;
