import React, { useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { Copy, MinusCircle, PlusCircle, X } from "lucide-react";
import "./modalRetailer.css";
import { getUser } from "../../../services/auth";
import { createMember } from "../../../services/subRetailerServices";
import { toast } from "react-toastify";
import { useAppContext } from "../../../context/AppContext";
import ClipboardCopy from "../../../utils/copyToClipboard";
import { useQuery } from "react-query";
import { getStateLists } from "../../../services/country/countryServices";

const AddSubRetailerModal = ({ setRetailerDetails }) => {
  const { setModal, setRetailRefresh, userData } = useAppContext();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    balance: "0",
    address: "",
    state: "",
    pincode: "",
    retailerPromotionalBalance: 0,
  });

  const [show, setShow] = useState(false);

  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const { data: stats } = useQuery({
    queryKey: ["stats"],
    queryFn: async () => {
      const user = await getUser();
      const res = await getStateLists(user);
      return res;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const maxBalance = () => {
    setFormData((prev) => ({
      ...prev,
      retailerPromotionalBalance: userData?.retailerPromotionalBalance,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        formData.retailerPromotionalBalance >
        userData.retailerPromotionalBalance
      ) {
        toast.error("Insufficient Promotional Balance!!!");
        return;
      }
      setModal("pending");
      const user = await getUser();
      const formDataToSubmit = { ...formData };

      if (!formDataToSubmit.state) {
        formDataToSubmit.state = undefined;
      }

      if (!formDataToSubmit.pincode) {
        formDataToSubmit.pincode = undefined;
      }
      const data = await createMember(user, formDataToSubmit);
      if (data.status) {
        setRetailerDetails(data.data);
        toast.success(data.message);
        setRetailRefresh(true);
        setModal("success-details");
      } else {
        setModal("add-retailer");
        toast.error(data.message);
      }
    } catch (error) {
      setModal("");
      toast.error("Someting went wrong!");
      console.log(error);
    }
  };

  const isFormValid =
    formData.name && formData.email && formData.mobile && formData.password;

  // console.log("userData", userData);

  return (
    <>
      <ModalLayout maxWidth="max-w-2xl">
        <div className="bg-white">
          <div className="flex justify-between items-center p-4 border-b">
            <div>
              <h1 className="text-lg font-semibold tracking-wide">
                Add Sub Retailer
              </h1>
              <p className="text-sm text-gray-500">
                Earn slab benefits (upto 25%) by adding a Sub-Retailer.
              </p>
            </div>
            <button
              onClick={() => setModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>

          <div className=" p-4 w-full mt-2">
            <div className="flex flex-col sm:flex-row gap-4 justify-between">
              <div className="space-y-4 text-sm w-full ">
                <div className="flex flex-col gap-0.5">
                  <h1 className="flex-1 text-sm font-medium text-blue-600">
                    Sub Retailer Name
                  </h1>
                  <input
                    type="name"
                    placeholder="Enter Full Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="h-10 border border-custom-border/30 rounded-lg px-2"
                  />
                </div>
                <div className="flex flex-col gap-0.5">
                  <h1 className="flex-1 text-sm font-medium text-blue-600">
                    Email ID
                  </h1>
                  <input
                    type="email"
                    placeholder="Enter Email ID"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="h-10 border border-custom-border/30 rounded-lg px-2"
                  />
                </div>
                <div className="flex flex-col gap-0.5">
                  <h1 className="flex-1 text-sm font-medium text-blue-600">
                    Mobile Number
                  </h1>
                  <input
                    type="number"
                    placeholder="Enter Mobile Number"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="h-10 border border-custom-border/30 rounded-lg px-2"
                  />
                </div>
                <div className="flex flex-col gap-0.5">
                  <h1 className="flex-1 text-sm font-medium text-blue-600">
                    Promotional Balance ({userData?.retailerPromotionalBalance}{" "}
                    <span className="text-black">
                      {userData?.country?.currencyCode})
                    </span>
                  </h1>
                  <div className="border border-custom-border/30 rounded-lg px-2 flex justify-between items-center w-full">
                    <input
                      type="number"
                      placeholder="Enter Promotional Balance"
                      name="retailerPromotionalBalance"
                      value={formData.retailerPromotionalBalance}
                      onChange={handleChange}
                      className="h-10 w-full outline-none"
                    />
                    <button
                      onClick={maxBalance}
                      className="bg-blue-100 hover:bg-blue-200 rounded-md p-1 px-2 font-medium text-blue-600"
                    >
                      MAX
                    </button>
                  </div>
                </div>
                <div className=" flex flex-col gap-0.5">
                  <span className="flex gap-1 font-medium text-blue-600">
                    Balance (
                    {userData?.fundBalance
                      ? userData?.fundBalance?.toLocaleString()
                      : "0.00"}{" "}
                    <span className="text-black">
                      {userData?.country?.currencyCode})
                    </span>
                    <button
                      title="Add"
                      onClick={() => setShow(!show)}
                      className="bg-blue-100 hover:bg-blue-200 px-1 rounded-md"
                    >
                      <PlusCircle className="w-3 h-3 " />
                    </button>
                  </span>
                  {show ? (
                    <div className="w-full mt-1">
                      <input
                        placeholder="Enter Amount"
                        name="balance"
                        value={formData.balance}
                        onChange={handleChange}
                        onFocus={(e) => (e.target.type = "number")}
                        onBlur={(e) =>
                          (e.target.type = formData.balance ? "number" : "text")
                        }
                        className="h-10 border border-custom-border/50 rounded-lg px-2 w-full"
                      />
                    </div>
                  ) : (
                    <p className="text-blue-500">
                      Click the + button to add an amount
                    </p>
                  )}
                </div>
              </div>

              <div className="space-y-4 text-sm w-full ">
                <div className="flex flex-col gap-0.5 ">
                  <h1 className="text-sm flex-1 font-medium text-blue-600">
                    Password
                  </h1>
                  <div className="h-10 border border-custom-border/30 rounded-lg px-2 flex items-center ">
                    <input
                      style={{ border: "none", boxShadow: "none" }}
                      type="password"
                      placeholder="************"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="outline-none w-full"
                    />
                    <button onClick={() => ClipboardCopy(formData.password)}>
                      <Copy style={{ width: "14px" }} />
                    </button>
                  </div>
                </div>
                <div className="flex flex-col gap-0.5">
                  <h1 className="flex-1 text-sm font-medium text-blue-600">
                    Country
                  </h1>
                  <select
                    disabled
                    className="border px-2 h-10 rounded-lg border-custom-border/30 outline-none disabled:cursor-not-allowed disabled:bg-black/10 opacity-50 "
                  >
                    <option value="">Select Country</option>
                    <option value="India">India</option>
                    <option value="BD">Bangladesh</option>
                    <option value="USA">USA</option>
                  </select>
                </div>
                {/* {userData?.country?.country === "India" && ( */}
                <div className="flex flex-col gap-0.5">
                  <h1 className="text-sm font-medium text-blue-600 flex-1">
                    States
                  </h1>
                  <select
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className="border px-2 h-10 rounded-lg border-custom-border/30 outline-none "
                  >
                    <option value="">Select</option>
                    {stats?.data?.map((state, id) => (
                      <option key={id} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                {/* )} */}
                <div className="flex flex-col gap-0.5">
                  <h1 className="flex-1 text-sm font-medium text-blue-600">
                    Pincode
                  </h1>
                  <input
                    type="number"
                    placeholder="Enter Pincode"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    className="h-10 border border-custom-border/30 rounded-lg px-2 "
                  />
                </div>
                <div className="flex flex-col gap-0.5">
                  <h1 className="flex-1 text-blue-600 font-medium">Address</h1>
                  <textarea
                    type="address"
                    placeholder="Enter your address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="border border-custom-border/30 rounded-lg p-2 mt-1"
                  />
                </div>
              </div>
            </div>

            <div className="w-full mt-12">
              <button
                onClick={handleSubmit}
                disabled={!isFormValid}
                style={{
                  cursor: isFormValid ? "pointer" : "not-allowed",
                  opacity: isFormValid ? 1 : 0.5,
                }}
                className="w-full bg-bg-color hover:bg-bg-color/80 h-10 rounded-xl text-white"
              >
                Add Sub Retailer
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default AddSubRetailerModal;
