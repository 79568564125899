import { httpAxios } from "../helper/httpHelper";

export async function fetchAds(user, page, limit, searchQuery) {
  try {
    // Construct the query string based on the presence of searchQuery
    let queryString = `/retailer/advertisement/list?page=${page}&limit=${limit}`;
    if (searchQuery) {
      queryString += `&search=${encodeURIComponent(searchQuery)}`;
    }

    const { data } = await httpAxios.get(queryString, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchPackagePrice(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/advertisement/price/create/list`,
      {
        headers: {
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createAdsBanner(user, formData) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/advertisement/add`,
      formData,
      {
        headers: {
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function disableAds(user, advertisementId) {
  try {
    const { data } = await httpAxios.put(
      `/retailer/advertisement/update/disable`,
      {
        advertisementId: advertisementId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function enableAds(user, advertisementId) {
  try {
    const { data } = await httpAxios.put(
      `/retailer/advertisement/update/enable`,
      {
        advertisementId: advertisementId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function editAdsBanner(user, formData) {
  try {
    console.log("formData", formData);
    
    const { data } = await httpAxios.put(
      `/retailer/advertisement/update`,
      formData,
      {
        headers: {
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteAdsBanner(user, advertisementId) {
  try {
    const { data } = await httpAxios.delete(`/retailer/advertisement/delete`, {
      params: { advertisementId },
      headers: {
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}
