import React, { useState, useEffect, useCallback, memo } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { Search, X } from "lucide-react";
import SearchUser from "../../DashboardComponents/SearchUser";
import {
  searchRetailersById,
  searchUserById,
  userTransferHistory,
} from "../../../services/dashboardServices";
import FundTables from "../../FundTables";
import Pagination from "../../Pagination";
import { getUser } from "../../../services/auth";
import { useAppContext } from "../../../context/AppContext";

const SearchUserModal = ({ setModal }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [searchedUser, setSearchedUser] = useState({});
  const [transferHistory, setTransferHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState("");

  const { userData } = useAppContext();

  const limit = 10;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Fetch User Details
  const handleGetUser = useCallback(async (query) => {
    if (query) {
      try {
        const user = await getUser();
        let data;
        if (userData && userData.role === "STATE") {
          data = await searchRetailersById(user, query);
        } else {
          data = await searchUserById(user, query);
        }
        if (data.status) {
          setSearchedUser(data.data);
        } else {
          setError("User not found");
        }
      } catch (error) {
        setSearchedUser({});

        console.log(error);
      }
    }
  }, []);

  // Fetch User Transfer History
  const handleUserHistory = useCallback(async (userId, page, limit) => {
    try {
      const user = await getUser();
      const data = await userTransferHistory(user, userId, page, limit);
      setTransferHistory(data.data.docs);
      setTotalPages(data.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (debouncedQuery) {
      handleGetUser(debouncedQuery);
    } else {
      setSearchedUser({});
    }
  }, [debouncedQuery, handleGetUser]);

  useEffect(() => {
    if (searchedUser._id && debouncedQuery !== "") {
      handleUserHistory(searchedUser._id, currentPage, limit);
    } else {
      setTransferHistory([]);
      setTotalPages(1);
    }
  }, [searchedUser, debouncedQuery, currentPage, limit, handleUserHistory]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // console.log("searchedUser", searchedUser);
  

  return (
    <>
      <ModalLayout maxWidth="max-w-2xl">
        <div className="bg-white w-full">
          <div className="modal-header">
            <div className="modal-header-body"></div>
            <div className="modal-header-body2">Transfer Fund</div>
            <div onClick={() => setModal("")} className="modal-header-body">
              <X style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="search-modal-body">
            <div className="flex items-center gap-1.5 border border-custom-border/30 h-10 px-2 rounded-xl">
              <span className="input-icon">
                <Search style={{ width: "20px" }} />
              </span>
              <input
                type="text"
                title="search"
                name="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Member ID / Email ID/ Mobile Number"
                className="w-full outline-none bg-transparent"
              />
            </div>
            <div style={{ marginTop: "25px" }}>
              {searchedUser._id && debouncedQuery !== "" ? (
                <SearchUser searchedUser={searchedUser} />
              ) : (
                <div style={{ textAlign: "center" }}>
                  {error === "User not found" ? error : null}
                </div>
              )}
            </div>
            <div className="py-4">
              <FundTables
                data={transferHistory}
                currentPage={currentPage}
                limit={limit}
              />
            </div>
            <div className="flex justify-center items-center">
              {transferHistory.length > 0 || transferHistory.totalPages >= 2 ? (
                <div className="pagination-container">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default memo(SearchUserModal);
