import { httpAxios } from "../helper/httpHelper";

export async function getSubscribersList(user, page, limit, searchQuery) {
  try {
    let url = `/retailer/subscriber/list?page=${page}&limit=${limit}`;

    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }

    const { data } = await httpAxios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user}`,
      },
    });

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function getRenewOptionsList(user) {
  try {
    const { data } = await httpAxios.get(
      `/retailer/subscriber/renew/option/list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function renewCheckout(user, userId, subscriptionOptionId) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/subscriber/renew/checkout`,
      { userId, subscriptionOptionId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}

export async function renew(user, checkoutId) {
  try {
    const { data } = await httpAxios.post(
      `/retailer/subscriber/renew`,
      { checkoutId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${user}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch subscribers"
    );
  }
}
