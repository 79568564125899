import React, { useRef, useState } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { ImageUp, Trash2, X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import { getUser } from "../../services/auth";
import { createOfferBenefit } from "../../services/manageOfferServices";
import { toast } from "react-toastify";
import ImageOverlayEditor from "../Editors/ImageOverlayEditor";
import KonvaEditor from "../Editors/KonvaEditor";
import convertSvgToPng from "../../utils/convertToPng";

const AddOfferBenefitModal = ({ triggerRefetch }) => {
  const [formData, setFormData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    url: "",
    image: "",
  });

  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const svgRef = useRef(null);
  const [error, setError] = useState("");

  const { modal, setModal } = useAppContext();

  // For upload a image file
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (validTypes.includes(file.type)) {
        setUploadedFile(file);
        setFormData({ ...formData, image: URL.createObjectURL(file) }); // Update the form data with the uploaded file URL
        setError("");
      } else {
        setError("Invalid file format. Only PNG, JPEG, and JPG are allowed.");
        setUploadedFile(null);
      }
    }
  };

  // For input chnages only
  const handleInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // For delete the uploaded image file
  const handleDeleteFile = () => {
    setUploadedFile(null);
    setFormData({ ...formData, image: "" }); // Reset the image field
    setError("");
  };

  const addOffer = async () => {
    try {
      const user = await getUser();
  
      // Ensure svgRef is available
      if (!svgRef.current) {
        console.error("SVG reference is missing!");
        return;
      }
  
      // Convert SVG to PNG and wait for the result
      const pngFile = await convertSvgToPng(svgRef, selectedImageId);
  
      // Create FormData instance
      const formDataToSend = new FormData();
  
      // Append form data fields
      formDataToSend.append("title", formData.title);
      formDataToSend.append("startDate", formData.startDate);
      formDataToSend.append("endDate", formData.endDate);
      formDataToSend.append("url", formData.url);
      formDataToSend.append("image", pngFile); // Append the generated PNG file
  
      // Send the request
      const res = await createOfferBenefit(user, formDataToSend);
  
      if (res.status) {
        setModal("");
        setFormData({
          title: "",
          startDate: "",
          endDate: "",
          url: "",
          image: "",
        });
        triggerRefetch();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  

  return (
    <>
      <ModalLayout maxWidth="max-w-lg">
        <div>
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Add Offer Benefit</h1>
            <button
              onClick={() => setModal("")}
              className="rounded-full hover:bg-gray-200 hover:text-red-600 p-1"
            >
              <X />
            </button>
          </div>

          <div className="p-4 py-8 space-y-6 ">
            <span className="flex justify-start items-center gap-2 w-full h-full">
              <h1 className="text-sm">Title:</h1>
              <input
                name="title"
                onChange={handleInputChange}
                placeholder="Enter Banner Title"
                className="border h-10 rounded-xl w-full outline-none p-2 text-sm"
              />
            </span>
            <div className="flex flex-col sm:flex-row gap-4">
              <span className="flex flex-col justify-start gap-1 w-full h-full">
                <h1 className="text-sm">Start Date:</h1>
                <input
                  name="startDate"
                  onChange={handleInputChange}
                  type="date"
                  placeholder="Enter Start Date"
                  className="border h-10 rounded-xl w-full outline-none p-2 text-sm"
                />
              </span>
              <span className="flex flex-col justify-start gap-1 w-full h-full">
                <h1 className="text-sm">End Date:</h1>
                <input
                  name="endDate"
                  onChange={handleInputChange}
                  type="date"
                  placeholder="Enter End Date"
                  className="border h-10 rounded-xl w-full outline-none p-2 text-sm"
                />
              </span>
            </div>
            <div>
              <ImageOverlayEditor
                selectedImageId={selectedImageId}
                setSelectedImageId={setSelectedImageId}
                svgRef={svgRef}
              />
              {/* <TestSvg /> */}
              {/* <KonvaEditor /> */}
            </div>
            {/* <div className="relative w-full">
              <input
                type="file"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                id="fileInput"
                onChange={handleFileChange}
              />
              <label
                htmlFor="fileInput"
                className="flex flex-col gap-2 items-center justify-center w-full h-32 border rounded-xl cursor-pointer"
              >
                {uploadedFile || formData.image ? (
                  <img
                    src={
                      uploadedFile
                        ? URL.createObjectURL(uploadedFile)
                        : formData.image
                    }
                    alt="Upload Preview"
                    className="h-full w-full object-cover rounded-xl"
                  />
                ) : (
                  <>
                    <ImageUp />
                    <p className="text-xs">Upload your banner</p>
                  </>
                )}
              </label>
              <span className="flex justify-between items-center relative">
                <p className="text-xs mt-1 text-neutral-400 text-right">
                  Max Size: 312 X 128
                </p>
                <p className="text-xs mt-1 text-neutral-400 text-right relative">
                  Format: PNG, JPG, JPEG
                </p>
              </span>
              {error && <p className="text-red-500 text-xs pt-2">{error}</p>}
              {uploadedFile && (
                <div className="w-full relative bg-white h-10 rounded-lg flex justify-end items-center ">
                  <button
                    title="Delete"
                    onClick={handleDeleteFile}
                    className="p-1 bg-red-300 rounded-md hover:bg-opacity-85"
                  >
                    <Trash2 className="w-4 h-4 text-red-500" />
                  </button>
                </div>
              )}
            </div>
              */}
            <div>
              <input
                name="url"
                onChange={handleInputChange}
                placeholder="URL to upload image"
                className="w-full h--10 border p-2 rounded-xl outline-none text-sm"
              />
            </div>
          </div>

          <div className="px-4 pb-4">
            <button
              onClick={addOffer}
              className="w-full h-12 bg-custom-border text-white text-sm rounded-xl"
            >
              Add Offer
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default AddOfferBenefitModal;
