import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import { useQuery } from "react-query";
import { getUser } from "../../../services/auth";
import { ChevronDown } from "lucide-react";
import { getCountryLineChartData } from "../../../services/country/countryServices";

const CountryLineChart = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSelectedYear(currentYear);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const years = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
  ];

  const { data: countryLineChartData } = useQuery({
    queryKey: ["countryChart", selectedYear],
    queryFn: async () => {
      const user = await getUser();
      const res = await getCountryLineChartData(user, selectedYear);
      return res.data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  // console.log("countryLineChartData", countryLineChartData);

  useEffect(() => {
    if (countryLineChartData && countryLineChartData.length) {
      const formattedData = countryLineChartData
        ?.map((monthData, index) => {
          if (monthData.length > 0) {
            return monthData.map((service) => ({
              x: index,
              y: service.totalAmount,
              transactionType: service.transactionType,
            }));
          }
          return { x: index, y: 0, transactionType: null };
        })
        .flat();

      Highcharts.chart("line-container", {
        chart: {
          type: "area",
          backgroundColor: null,
          plotShadow: false,
          style: {
            fontFamily: "SUSE, sans-serif",
          },
        },
        title: {
          text: "",
        },
        accessibility: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          gridLineWidth: 1,
          lineColor: "#D3D3D3",
          tickColor: "#D3D3D3",
          tickmarkPlacement: "between",
        },
        yAxis: {
          title: {
            text: null,
          },
          min: 0,
          max: 10000,
          gridLineWidth: 0,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillOpacity: 0.5,
            marker: {
              enabled: true,
              fillColor: "rgba(124, 58, 237, 1)",
              lineWidth: 2,
              lineColor: "#fff",
            },
          },
        },
        series: [
          {
            name: "Services",
            data: formattedData,
            color: "rgba(124, 58, 237, 1)",
            fillColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "rgba(124, 58, 237, 0.5)"],
                [1, "rgba(124, 58, 237, 0)"],
              ],
            },
            pointPlacement: "on",
          },
        ],
        tooltip: {
          formatter: function () {
            let tooltipContent = `<b>${this.series.name}</b><br/>Month: ${this.point.category}<br/>`;
            const servicesData = countryLineChartData[this.point.x];
            servicesData?.forEach((service) => {
              tooltipContent += `Total Amount: ${service?.totalAmount}<br/>Service Name: ${service?.transactionType}<br/>`;
            });
            return tooltipContent;
          },
        },
        style: {
          fontFamily: "SUSE, Arial, sans-serif", // Ensure fallback fonts if SUSE is not available
        },
      });
    }
  }, [countryLineChartData]);

  return (
    <div className="border rounded-xl shadow-lg p-4 w-full h-full">
      <div className="flex justify-between items-center pb-8">
        <h1 className="sm:text-lg font-semibold">Service Analytics</h1>
        <div className="flex items-center gap-4">
          <div className="relative h-auto">
            <button
              title="year"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex gap-4 justify-center items-center border hover:border-purple-500 px-2 py-1.5 rounded-xl text-sm sm:text-base"
            >
              {selectedYear}
              <ChevronDown className="w-5 h-5" />
            </button>
            {isDropdownOpen && (
              <div
                className="absolute right-0 border p-2 rounded-xl mt-2 w-36 z-50 bg-white space-y-1 shadow-lg text-sm sm:text-base"
                ref={dropdownRef}
              >
                {years.map((year) => (
                  <div
                    key={year}
                    className={`p-2 cursor-pointer rounded-lg transition-all duration-150 ${
                      selectedYear === year
                        ? "bg-purple-500 text-white"
                        : "hover:bg-gray-200"
                    }`}
                    onClick={() => {
                      setSelectedYear(year);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {year}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div id="line-container"></div>
    </div>
  );
};

export default CountryLineChart;
