import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { useQuery } from "react-query";
import { getUser } from "../../../services/auth";
import {
  getAvgBalanceData,
  getLineChartData,
} from "../../../services/state/retailerServices";
import YearSelector from "../../Dropdowns/YearSelector";
import OptionSelector from "../../Dropdowns/OptionSelector";

const LineChart = () => {
  const [selectedOption, setSelectedOption] = useState("Avg. Balance");
  const [selectedYear, setSelectedYear] = useState("");

  const { data: lineChartData } = useQuery({
    queryKey: ["lineChartData", selectedOption, selectedYear],
    queryFn: async () => {
      const user = await getUser();

      if (selectedOption === "Avg. Balance") {
        const response = await getAvgBalanceData(user, selectedYear);
        return response.data.map((amount, index) => ({
          totalAmount: amount ?? 0, // Replace null with 0
          transactionType: "Avg. Balance",
          monthIndex: index, // Keep track of months
        }));
      } else {
        const response = await getLineChartData(user, selectedYear);
        return response.data;
      }
    },
    enabled: !!selectedOption,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  // console.log("lineChartData", lineChartData);

  useEffect(() => {
    let formattedData = [];

    if (lineChartData && lineChartData.length) {
      if (selectedOption === "Avg. Balance") {
        // Handle getAvgBalanceData response
        formattedData = lineChartData.map((monthData, index) => ({
          x: index, // Month index
          y: monthData.totalAmount ?? 0, // Ensure totalAmount is not null
          transactionType: monthData.transactionType,
        }));
      } else {
        // Handle getLineChartData response
        formattedData = lineChartData
          .map((monthData, index) => {
            if (monthData?.length > 0) {
              return monthData.map((service) => ({
                x: index,
                y: service.totalAmount ?? 0,
                transactionType: service.transactionType,
              }));
            }
            return { x: index, y: 0, transactionType: null }; // Ensure empty months have y = 0
          })
          .flat();
      }
    } else {
      // When there is no data, set all months to y = 0
      formattedData = Array.from({ length: 12 }, (_, index) => ({
        x: index,
        y: 0,
        transactionType: null,
      }));
    }

    Highcharts.chart("line-container", {
      chart: {
        type: "area",
        backgroundColor: null,
        plotShadow: false,
        style: {
          fontFamily: "SUSE, sans-serif",
        },
      },
      title: {
        text: "",
      },
      accessibility: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        gridLineWidth: 1,
        lineColor: "#D3D3D3",
        tickColor: "#D3D3D3",
        tickmarkPlacement: "between",
      },
      yAxis: {
        title: {
          text: null,
        },
        min: 0, // Ensure chart starts from the bottom
        gridLineWidth: 0,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          fillOpacity: 0.5,
          marker: {
            enabled: true,
            fillColor: "rgba(124, 58, 237, 1)",
            lineWidth: 2,
            lineColor: "#fff",
          },
        },
      },
      series: [
        {
          name: selectedOption,
          data: formattedData,
          color: "rgba(124, 58, 237, 1)",
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "rgba(124, 58, 237, 0.5)"],
              [1, "rgba(124, 58, 237, 0)"],
            ],
          },
          pointPlacement: "on",
        },
      ],
      tooltip: {
        formatter: function () {
          let tooltipContent = `<b>${this.series.name}</b><br/>Month: ${this.point.category}<br/>`;

          if (selectedOption === "Avg. Balance") {
            tooltipContent += `Total Amount: ${
              (this.point.y)?.toLocaleString()
            }<br/>Service Name: ${this.point.transactionType ?? "N/A"}<br/>`;
          } else {
            const servicesData = lineChartData?.[this.point.x] || [];
            servicesData.forEach((service) => {
              tooltipContent += `Total Amount: ${
                service?.totalAmount?.toLocaleString()
              }<br/>Service Name: ${service?.transactionType ?? "N/A"}<br/>`;
            });
          }
          return tooltipContent;
        },
      },
      style: {
        fontFamily: "SUSE, Arial, sans-serif",
      },
    });
  }, [lineChartData, selectedOption]);

  return (
    <div className="border rounded-xl shadow-lg p-4 w-full h-full">
      <div className="flex justify-between items-center pb-8 w-full">
        <div className="flex flex-wrap gap-2 justify-between items-center w-full">
          <h1 className="sm:text-lg font-semibold">Service Analytics</h1>
          <div className="flex gap-2">
            <OptionSelector
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <YearSelector
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </div>
        </div>
      </div>

      <div id="line-container"></div>
    </div>
  );
};

export default LineChart;
