import html2canvas from "html2canvas";

// Convert to png and download it
const convertSvgToPng = (svgRef, selectedImageId) => {
    return new Promise((resolve, reject) => {
      if (!svgRef.current) {
        reject("SVG reference is missing!");
        return;
      }
  
      html2canvas(svgRef.current, { useCORS: true }).then((canvas) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject("Failed to generate PNG file.");
            return;
          }
  
          const pngFile = new File([blob], `template-${selectedImageId}.png`, {
            type: "image/png",
          });
  
          resolve(pngFile); // Return the generated file
        }, "image/png");
      });
    });
  };
  
  export default convertSvgToPng;
  
