import { ImageUp, Trash2 } from "lucide-react";
import React, { useState } from "react";
import { getUser } from "../../services/auth";
import { createAdsBanner } from "../../services/manageAdsServices";
import { toast } from "react-toastify";
import AdsPackageSelector from "../Dropdowns/AdsPackageSelector";

const AddAdvertiseForm = ({ triggerFetch }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    url: "",
    startDate: "",
    // endDate: "",
    image: "",
    bannerPriceId: "",
  });

  // console.log("formData", formData);

  // For input changes
  const handleInputChanges = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // For set the banner images
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (validTypes.includes(file.type)) {
        setUploadedFile(file);
        setFormData({ ...formData, image: URL.createObjectURL(file) });
        setError("");
      } else {
        setError("Invalid file format. Only PNG, JPEG, and JPG are allowed.");
        setUploadedFile(null);
      }
    }
  };

  // For delete the banner images
  const handleDeleteFile = () => {
    setUploadedFile(null);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await getUser();
      // const res = await

      const formDataToSend = new FormData();

      // Append form data (including the file)
      formDataToSend.append("title", formData.title);
      formDataToSend.append("startDate", formData.startDate);
      // formDataToSend.append("endDate", formData.endDate);
      formDataToSend.append("url", formData.url);
      formDataToSend.append("bannerPriceId", selectedOption?._id);
      if (uploadedFile) {
        formDataToSend.append("image", uploadedFile); // Append the actual file, not the blob URL
      }

      const res = await createAdsBanner(user, formDataToSend);

      if (res.status) {
        setFormData({
          title: "",
          url: "",
          startDate: "",
          // endDate: "",
          image: "",
        });
        setUploadedFile(null);
        triggerFetch();
        toast.success(res.message);
      } else {
        toast.error(res.message);
        console.error(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div>
        <form className="h-full" onSubmit={handleSubmit}>
          <div className="flex flex-col sm:flex-row justify-between gap-4 w-full h-full">
            <div className="flex flex-col gap-6 w-full">
              <span className="space-y-1">
                <h1 className="text-sm">Add Title</h1>
                <input
                  name="title"
                  value={formData.title}
                  onChange={handleInputChanges}
                  placeholder="Enter Title"
                  type="text"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="flex flex-col sm:flex-row gap-4 w-full">
                <span className="space-y-1 w-full">
                  <h1 className="text-sm">Start Date</h1>
                  <input
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleInputChanges}
                    placeholder="Enter name"
                    type="date"
                    required
                    className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                  />
                </span>
                {/* <span className="space-y-1 w-full">
                  <h1 className="text-sm">End Date</h1>
                  <input
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleInputChanges}
                    placeholder="Enter name"
                    type="date"
                    required
                    className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                  />
                </span> */}
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">URL</h1>
                <input
                  name="url"
                  value={formData.url}
                  onChange={handleInputChanges}
                  placeholder="Enter URL"
                  type="url"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">Select Package</h1>
                <AdsPackageSelector
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </span>
            </div>
            <div className="flex flex-col gap-4 w-full ">
              <div className="relative w-full">
                <h1 className="text-sm mb-1">Advertisement Banner</h1>
                <input
                  type="file"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  id="fileInput"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="fileInput"
                  className="flex flex-col gap-2 items-center justify-center w-full h-32 border rounded-xl cursor-pointer"
                >
                  {uploadedFile ? (
                    <img
                      src={URL.createObjectURL(uploadedFile)}
                      alt="Upload Preview"
                      className="h-full w-full object-cover rounded-xl"
                    />
                  ) : (
                    <>
                      <ImageUp />
                      <p className="text-xs">Upload you banner</p>
                    </>
                  )}
                </label>
                <span className="flex justify-between items-center relative">
                  <p className="text-xs mt-1 text-neutral-400 text-right">
                    Max Size: 312 X 128
                  </p>
                  <p className="text-xs mt-1 text-neutral-400 text-right relative">
                    Format: PNG, JPG, JPEG
                  </p>
                </span>
                {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
                {uploadedFile && (
                  <div className="w-full relative bg-white h-10 rounded-lg flex justify-end items-center ">
                    <button
                      title="Delete"
                      onClick={handleDeleteFile}
                      className="p-1 bg-red-300 rounded-md hover:bg-opacity-85"
                    >
                      <Trash2 className="w-4 h-4 text-red-500" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="h-full mt-52 flex justify-center items-center">
            <button
              type="submit"
              className="bg-custom-border w-96 p-2 rounded-xl text-white"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddAdvertiseForm;
