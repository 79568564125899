import { ChevronDown } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

const YearSelector = ({ selectedYear, setSelectedYear }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const years = [
    new Date().getFullYear(),
    new Date().getFullYear() - 1,
    new Date().getFullYear() - 2,
  ];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSelectedYear(currentYear);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative h-auto">
        <button
          title="year"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex gap-4 justify-center items-center border hover:border-purple-500 px-2 py-1.5 rounded-xl text-sm sm:text-base"
        >
          {selectedYear}
          <ChevronDown className="w-5 h-5" />
        </button>
        {isDropdownOpen && (
          <div
            className="absolute right-0 border p-2 rounded-xl mt-2 w-36 z-50 bg-white space-y-1 shadow-lg text-sm sm:text-base"
            ref={dropdownRef}
          >
            {years.map((year) => (
              <div
                key={year}
                className={`p-2 cursor-pointer rounded-lg transition-all duration-150 ${
                  selectedYear === year
                    ? "bg-purple-500 text-white"
                    : "hover:bg-gray-200"
                }`}
                onClick={() => {
                  setSelectedYear(year);
                  setIsDropdownOpen(false);
                }}
              >
                {year}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default YearSelector;
