export const adManageArr = [
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: true,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: false,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: true,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: true,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: false,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: true,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: false,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: true,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: true,
  },
  {
    ad_title: "10% Off on Purchase",
    remaining_time: "00:55:12",
    status: false,
  },
];
