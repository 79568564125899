import React, { useState, useRef } from "react";
import TestSvg from "../testsvg";
import OfferBannerOne from "../OfferBannerOne";
import OfferBannerTwo from "../OfferBannerTwo";

const ImageOverlayEditor = ({
  svgRef,
  selectedImageId,
  setSelectedImageId,
}) => {
  const [formData, setFormData] = useState({
    text1: "",
    text2: "",
    text3: "",
    text4: "",
    text5: "",
    uploadedImage1: null,
    uploadedImage2: null,
  });


  const images = [
    { template: <OfferBannerOne />, id: 1 },
    { template: <OfferBannerTwo />, id: 2 },
    { template: <TestSvg />, id: 3 },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value || "" });
  };

  const handleImageUpload = (e, imageField) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({ ...formData, [imageField]: event.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  // const convertSvgToString = () => {
  //   if (!selectedImageId) return;

  //   // Find the selected template dynamically
  //   const selectedTemplate = images.find(
  //     (img) => img.id === selectedImageId
  //   )?.template;

  //   if (!selectedTemplate) return;

  //   const svgString = ReactDOMServer.renderToString(
  //     React.cloneElement(selectedTemplate, { formData })
  //   );

  //   // Create a Blob for download
  //   const blob = new Blob([svgString], { type: "image/svg+xml" });
  //   const url = URL.createObjectURL(blob);

  //   // Convert the Blob to a File
  //   const svgFile = new File([blob], `template-${selectedImageId}.svg`, {
  //     type: "image/svg+xml",
  //   });

  //   // Pass the file to handleFileChange
  //   handleFileChange({ target: { files: [svgFile] } });

  //   // Create a download link
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = `template-${selectedImageId}.svg`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(url);
  // };

  // const convertSvgToPng = async () => {
  //   if (!svgRef.current) return;

  //   html2canvas(svgRef.current, { useCORS: true }).then((canvas) => {
  //     canvas.toBlob((blob) => {
  //       if (!blob) return;

  //       const pngFile = new File([blob], `template-${selectedImageId}.png`, {
  //         type: "image/png",
  //       });

  //       // Pass the file to handleFileChange
  //       handleFileChange({ target: { files: [pngFile] } });

  //       // Create a download link
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       link.download = `template-${selectedImageId}.png`;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(link.href);
  //     }, "image/png");
  //   });
  // };

  return (
    <div className="flex flex-col items-center space-y-4 text-sm">
      {/* Image Selection Dropdown */}
      <div className="w-full space-y-1">
        <h1>Select a Template</h1>
        <select
          onChange={(e) =>
            setSelectedImageId(e.target.value ? Number(e.target.value) : null)
          }
          className="border p-1.5 rounded-xl w-full h-10 outline-none"
        >
          <option value="">Select Template</option>
          {images.map((image) => (
            <option key={image.id} value={image.id}>
              Template {image.id}
            </option>
          ))}
        </select>
      </div>

      {/* Show inputs only when a template is selected */}
      {selectedImageId && (
        <>
          {selectedImageId !== 3 ? (
            <div className="grid grid-cols-2 gap-2 sm:gap-4 text-sm w-full">
              <input
                type="text"
                name="text1"
                placeholder="Enter Text 1 (e.g. 10% OFF)"
                value={formData.text1}
                onChange={handleChange}
                className="border p-2 rounded-xl w-full"
              />
              <input
                type="text"
                name="text2"
                placeholder="Enter Text 2 (e.g. SALE)"
                value={formData.text2}
                onChange={handleChange}
                className="border p-2 rounded-xl w-full"
              />
              <input
                type="text"
                name="text3"
                placeholder="Enter Text 3 (e.g. off, extra)"
                value={formData.text3}
                onChange={handleChange}
                className="border p-2 rounded-xl w-full"
              />
              <input
                type="text"
                name="text4"
                placeholder="Enter Text 4 (e.g. top title)"
                value={formData.text4}
                onChange={handleChange}
                className="border p-2 rounded-xl w-full"
              />
              <input
                type="text"
                name="text5"
                placeholder="Enter Text 5 (e.g. bottom title)"
                value={formData.text5}
                onChange={handleChange}
                className="border p-2 rounded-xl w-full"
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-2 sm:gap-4 w-full">
              <input
                type="text"
                name="text1"
                placeholder="Enter Text 1 (e.g. 10% OFF)"
                value={formData.text1}
                onChange={handleChange}
                className="border p-2 rounded-xl w-full"
              />
              <label className="border p-2 rounded-xl w-full cursor-pointer block text-gray-400">
                Click to choose an image 1
                <input
                  type="file"
                  onChange={(e) => handleImageUpload(e, "uploadedImage1")}
                  className="hidden"
                />
              </label>

              <label className="border p-2 rounded-xl w-full cursor-pointer block text-gray-400">
                Click to choose an image 2
                <input
                  type="file"
                  onChange={(e) => handleImageUpload(e, "uploadedImage2")}
                  className="hidden"
                />
              </label>
            </div>
          )}
        </>
      )}

      {/* Display Selected Template */}
      {selectedImageId && (
        <div ref={svgRef} className="">
          {selectedImageId === 1 && <OfferBannerOne formData={formData} />}
          {selectedImageId === 2 && <OfferBannerTwo formData={formData} />}
          {selectedImageId === 3 && <TestSvg formData={formData} />}
        </div>
      )}

      {/* Buttons to Convert and Download */}
      {/* {selectedImageId && (
        <div className=" mt-4 grid grid-cols-2 gap-2 sm:gap-4 w-full">
          <button
            onClick={convertSvgToString}
            className="bg-blue-500 text-white p-2 rounded-xl w-full-xl w-full"
          >
            Download as SVG
          </button>
          <button
            onClick={convertSvgToPng}
            className="bg-green-500 text-white p-2 rounded-xl w-full-xl w-full"
          >
            Download as PNG
          </button>
        </div>
      )} */}
    </div>
  );
};

export default ImageOverlayEditor;
