import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HC_accessibility from "highcharts/modules/accessibility";
import { useQuery } from "react-query";
import { getDonutChartData } from "../../../services/state/retailerServices";
import { getUser } from "../../../services/auth";
import { AreaChart } from "lucide-react";

// Initialize the accessibility module
HC_accessibility(Highcharts);

const DonutChart = () => {
  const { data: chartData } = useQuery({
    queryKey: ["chartData"],
    queryFn: async () => {
      const user = await getUser();
      const res = await getDonutChartData(user);
      return res.data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  // console.log("chartData", chartData);

  useEffect(() => {
    if (chartData && chartData.length) {
      // Calculate the total amount
      const totalAmount = chartData?.reduce(
        (sum, service) => sum + service.totalAmount,
        0
      );

      // Format data for Highcharts
      const formattedData = chartData?.map((service) => ({
        name: `${((service.totalAmount / totalAmount) * 100).toFixed(1)}%`, // Convert to percentage
        y: (service.totalAmount / totalAmount) * 100,
        color:
          service._id === "RECHARGE"
            ? "#4A90E2"
            : service._id === "FUND-RESTORE-ACCOUNT"
            ? "#000000"
            : "#D3D3D3", // Example colors, adjust as needed
      }));

      Highcharts.chart("container", {
        chart: {
          type: "pie",
          backgroundColor: null,
          plotShadow: false,
          style: {
            fontFamily: "SUSE, sans-serif",
          },
        },
        title: {
          useHTML: true,
          text: `<div style="text-align: center; margin-top: 40px;"> <span style="font-size: 24px; font-weight: bold;">${totalAmount?.toLocaleString()}</span> <br> <span style="font-size: 16px;">Total Amount</span> </div>`,
          align: "center",
          verticalAlign: "middle",
          y: 0,
        },
        accessibility: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            innerSize: "50%",
            depth: 45,
            dataLabels: {
              enabled: true,
              distance: -50,
              format: "{point.name}",
            },
            states: {
              hover: {
                enabled: true,
                brightness: 0.2,
                shadow: {
                  color: "rgba(0, 0, 0, 0.3)",
                  width: 10,
                  offsetX: 0,
                  offsetY: 0,
                },
              },
            },
          },
        },
        series: [
          {
            name: "Services",
            data: formattedData,
          },
        ],
      });
    }
  }, [chartData]);

  return (
    <div className="border rounded-xl shadow-lg p-4 w-full h-full">
      <div>
        <h1 className="sm:text-lg font-semibold">Service Statistics</h1>
      </div>
      {chartData?.length > 0 ? (
        <div id="container"></div>
      ) : (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <AreaChart className="w-5 h-5"/>
          <h1 className="text-center text-sm font-medium">
            No data available.
          </h1>
        </div>
      )}
      <div className="grid sm:grid-cols-2 justify-between items-center gap-4 mt-4 text-sm">
        {chartData?.map((data, id) => (
          <div key={id} className="w-auto">
            <div className="flex gap-2 items-center">
              <div
                className={`w-4 h-4 rounded-full ${
                  data._id === "RECHARGE" ? "bg-[#4A90E2]" : "bg-[#000000]"
                }`}
              ></div>
              <h1>{data?._id}</h1>
            </div>
            <div className="mt-3">
              <div className="space-y-1">
                <p>
                  Total Amount :{" "}
                  {data?.totalAmount
                    ? data?.totalAmount?.toLocaleString()
                    : "0"}
                </p>
                <p>
                  Total Transactions :{" "}
                  {data?.totalTransactions
                    ? data?.totalTransactions?.toLocaleString()
                    : "0"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DonutChart;
