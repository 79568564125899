import React, { useState, useEffect } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { useAppContext } from "../../context/AppContext";
import { RefreshCcw, TicketSlash, X } from "lucide-react";
import { useQuery } from "react-query";
import { getUser } from "../../services/auth";
import { fetchOfferBenefitsForSubRetailers } from "../../services/manageOfferServices";
import { Link } from "react-router-dom";
import Pagination from "../Pagination";

const Countdown = ({ endDate }) => {
  const calculateTimeLeft = (endDate) => {
    const difference = new Date(endDate) - new Date();
    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      return `${days}d: ${hours}h: ${minutes}m: ${seconds}s`;
    }
    return "Expired";
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  return <span>{timeLeft}</span>;
};

const BannerModal = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 4;
  const { setModal } = useAppContext();

  const { data: offerBanners, isLoading } = useQuery({
    queryKey: ["offerBanners", currentPage, limit],
    queryFn: async () => {
      const user = await getUser();
      const res = await fetchOfferBenefitsForSubRetailers(
        user,
        currentPage,
        limit
      );
      return res.data;
    },
    onSuccess: (data) => {
      if (data) {
        setTotalPages(data.totalPages || 1);
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    // staleTime: 2 * 60 * 60 * 1000,
    enabled: currentPage > 0,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [setModal]);

  return (
    <ModalLayout maxWidth="max-w-lg">
      <div>
        <div className="flex justify-between items-center p-4 border-b">
          <h1 className="text-lg font-semibold font-suse tracking-wide">
            Offer Benefit Banners
          </h1>
          <button
            onClick={() => setModal("")}
            className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
          >
            <X />
          </button>
        </div>

        <div className="flex flex-col justify-center items-center py-4 w-full h-full">
          {isLoading ? (
            <div className="min-h-96 w-full flex flex-col gap-2 justify-center items-center font-suse">
              <RefreshCcw className="w-6 h-6 animate-spin" />
              <p>Loading banners...</p>
            </div>
          ) : offerBanners?.docs?.length > 0 ? (
            <div className="w-full h-full grid gap-4 max-h-[32rem] overflow-y-auto text-xs sm:text-sm px-4 pb-4">
              {offerBanners?.docs?.map((banner, id) => (
                <Link
                  target="_blank"
                  key={id}
                  to={banner.url}
                  className="w-full h-fit border border-blue-100 p-2 rounded-xl font-suse hover:bg-blue-50 shadow-md hover:shadow-sm transition-shadow ease-in-out duration-200"
                >
                  <div className="w-full h-full flex items-center justify-center overflow-hidden rounded-lg relative">
                    {/* <span className="p-1 px-2 bg-black/50 backdrop-blur rounded-lg absolute top-2 left-2">
                      <h2 className="text-md font-medium text-white ">
                        Title: {banner.title}
                      </h2>
                    </span> */}
                    <img
                      src={banner.image}
                      alt="Offer Banner"
                      loading="lazy"
                      className="w-full h-full object-fill"
                    />
                  </div>
                  <div className="flex justify-between items-center mt-1.5 ">
                    <p>View Count: {banner.viewCount}</p>
                    <p className="">
                      <span className="text-red-500 font-semibold">
                        Ends In:
                      </span>{" "}
                      <Countdown endDate={banner.endDate} />
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="min-h-96 w-full flex flex-col justify-center items-center font-suse">
              <TicketSlash className="w-6 h-6" />
              <p>Banners not found.</p>
            </div>
          )}
        </div>

        {totalPages > 1 && (
          <div className=" flex justify-center items-center gap-4 w-full p-4 sm:p-2 -mt-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

export default BannerModal;
