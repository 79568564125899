import React, { useEffect } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import { useAppContext } from "../../../context/AppContext";
import { getUserProfile } from "../../../services/userServices";
import { getUser } from "../../../services/auth";

const SuccessDetailsModal = ({ retailerDetails, role="sub-retailer" }) => {
  const { setModal, userProfile, setUserProfile } = useAppContext();

  // console.log(retailerDetails);

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const user = await getUser();
        const data = await getUserProfile(user);

        if (data.status) {
          // console.log("User Info", data.data);
          setUserProfile(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    // console.log("userProfile",userProfile);

    getUserDetails();
  }, []);

  const totalAmount =
    retailerDetails?.amountDeducted +
    Number(retailerDetails?.amountTransferredToSubretailer); 

    // console.log("retailerDetails", retailerDetails);
    // console.log("totalAmount", totalAmount);

  return (
    <>
      <ModalLayout maxWidth="max-w-fit">
        <div className="success-modal-container">
          <div>
            <div className="loading-gif-new">
              <img src="/images/success.gif" alt="gif" loading="lazy" />
            </div>
            <div style={{ textAlign: "center", color: "rgba(1, 166, 57, 1)" }}>
              <p>Congratulations</p>
            </div>
            <div style={{ textAlign: "center", padding: "10px 0" }}>
              <p style={{ fontSize: "14px" }}>
                Thank you for adding a new {role}. The process was
                completed successfully.
              </p>
            </div>
            <div
              style={{ textAlign: "center", marginTop: "12px" }}
              className="txn-portal"
            >
              <div className="txn-container" style={{ color: "green" }}>
                <h1>{role} Activation</h1>
                <span>:</span>
                <p style={{ fontSize: "14px", textAlign: "right" }}>
                  <b>{retailerDetails?.amountDeducted} </b>
                  {userProfile?.country?.currencyCode}
                </p>
              </div>
              <div className="txn-container" style={{ color: "red" }}>
                <h1>Balance Added</h1>
                <span>:</span>
                <p style={{ fontSize: "14px", textAlign: "right" }}>
                  <b>{retailerDetails?.amountTransferredToSubretailer} </b>
                  {userProfile?.country?.currencyCode}
                </p>
              </div>
              <div
                className="txn-container"
                style={{
                  borderTop: "1px solid black",
                  marginTop: "5px",
                  paddingTop: "5px",
                }}
              >
                <h1>Total Deducted Amount</h1>
                <span>:</span>
                <p style={{ fontSize: "14px", textAlign: "right" }}>
                  <b>{totalAmount} </b>
                  {userProfile?.country?.currencyCode}
                </p>
              </div>
            </div>
            <div className="" style={{ marginTop: "20px" }}>
              <button
                onClick={() => setModal("")}
                className="w-full bg-bg-color hover:bg-bg-color/80 h-10 rounded-lg font-semibold text-white"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default SuccessDetailsModal;
