import {
  ArrowUp,
  BarChart,
  Crown,
  Info,
  MonitorCheck,
  Send,
  SmartphoneCharging,
  Wallet,
} from "lucide-react";
import React from "react";
import { useQuery } from "react-query";
import { getUser } from "../../../services/auth";
import { getRetailerActivities } from "../../../services/state/retailerServices";
import { useAppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const RetailerActivities = () => {
  const { userData } = useAppContext();

  const { data, isLoading } = useQuery({
    queryKey: ["activities"],
    queryFn: async () => {
      const user = await getUser();
      const res = await getRetailerActivities(user);
      return res.data;
    },
    enabled: userData.role === "RETAILER",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval: 300000,
  });

  return (
    <>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
        {/* One */}
        <div className="border rounded-xl shadow-lg p-4">
          <div className="flex justify-between gap-2">
            <div className="w-full flex gap-2 xl:gap-4">
              <div className="w-1 h-full bg-purple-500 rounded-full"></div>
              <div>
                <h1 className="font-semibold mb-1 text-sm xl:text-base">
                  Active Sub Retailer
                </h1>
                <div className="text-sm xl:text-base">
                  {!isLoading ? (
                    data?.activeSubRetailerCount?.toLocaleString()
                  ) : (
                    <div className="w-20 h-5 bg-gray-200 rounded-xl animate-pulse"></div>
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <div className=" w-8 h-8 xl:w-11 xl:h-11 bg-purple-200 flex justify-center items-center rounded-lg p-1">
                <MonitorCheck className="w-full h-full text-purple-600" />
              </div>
            </div>
          </div>
          {/* <div className="text-xs flex gap-2 items-center mt-4">
            <ArrowUp className="text-emerald-500 w-4 h-4" />
            <p>
              <span className="text-emerald-500">+6.5%</span> Sincelast month
            </p>
          </div> */}
        </div>
        {/* Two */}
        <div className="border rounded-xl shadow-lg p-4">
          <div className="flex justify-between gap-2">
            <div className="w-full flex gap-2 xl:gap-4">
              <div className="w-1 h-full bg-green-500 rounded-full"></div>
              <div>
                <h1 className="font-semibold mb-1 text-sm xl:text-base">
                  Commission Earn
                </h1>
                <div className="text-sm xl:text-base">
                  {!isLoading ? (
                    data?.earnedCommission?.toLocaleString()
                  ) : (
                    <div className="w-20 h-5 bg-gray-200 rounded-xl animate-pulse"></div>
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <div className=" w-8 h-8 xl:w-11 xl:h-11 bg-green-200 flex justify-center items-center rounded-lg p-1">
                <BarChart className="w-full h-full text-green-600" />
              </div>
            </div>
          </div>
          {/* <div className="text-xs flex gap-2 items-center mt-4">
            <ArrowUp className="text-emerald-500 w-4 h-4" />
            <p>
              <span className="text-emerald-500">+6.5%</span> Sincelast month
            </p>
          </div> */}
        </div>
        {/* Three */}
        <Link
          to="/transfer-records"
          className="border rounded-xl shadow-lg p-4 hover:bg-blue-50"
        >
          <div className="flex justify-between gap-2">
            <div className="w-full flex gap-2 xl:gap-4">
              <div className="w-1 h-full bg-yellow-500 rounded-full"></div>
              <div>
                <div>
                  <h1 className="font-semibold mb-1 text-sm xl:text-base">
                    Balance
                  </h1>
                </div>
                <div className="text-sm xl:text-base">
                  {!isLoading ? (
                    data?.fundBalance?.toLocaleString()
                  ) : (
                    <div className="w-20 h-5 bg-gray-200 rounded-xl animate-pulse"></div>
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <div className=" w-8 h-8 xl:w-11 xl:h-11 bg-yellow-200 flex justify-center items-center rounded-lg p-1">
                <Wallet className="w-full h-full text-yellow-600" />
              </div>
            </div>
          </div>
          {/* <div className="flex gap-1 justify-between lg:flex-col xl:flex-row xl:items-center mt-4">
            <div className="text-xs flex gap-2 items-center">
              <ArrowUp className="text-emerald-500 w-4 h-4" />
              <p>
                <span className="text-emerald-500">+6.5%</span> Sincelast month
              </p>
            </div>
            <div className="flex items-end">
              <p className="text-blue-500 italic font-medium whitespace-nowrap text-xs">View more...</p>
            </div>
          </div> */}
        </Link>
        {/* Four */}
        <div className="border rounded-xl shadow-lg p-4">
          <div className="flex justify-between gap-2">
            <div className="w-full flex gap-2 xl:gap-4">
              <div className="w-1 h-full bg-sky-500 rounded-full"></div>
              <div>
                <div>
                  <h1 className="font-semibold mb-1 text-sm xl:text-base flex items-center gap-1">
                    Promotional Wallet{" "}
                    <span
                      id="my-tooltip"
                      data-tooltip-content="Earned via slab commission. Use up to 25% when adding a sub-retailer."
                    >
                      <Info className="w-4 h-4" />
                    </span>
                    {/* Tooltip should be placed separately */}
                    <Tooltip anchorSelect="#my-tooltip"  className="max-w-[225px] break-words"/>
                  </h1>
                </div>
                <div className="text-sm xl:text-base">
                  {!isLoading ? (
                    userData?.retailerPromotionalBalance?.toLocaleString()
                  ) : (
                    <div className="w-20 h-5 bg-gray-200 rounded-xl animate-pulse"></div>
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <div className=" w-8 h-8 xl:w-11 xl:h-11 bg-sky-200 flex justify-center items-center rounded-lg p-1">
                <Crown className="w-full h-full text-sky-600" />
              </div>
            </div>
          </div>
          {/* <div className="flex gap-1 justify-between lg:flex-col xl:flex-row xl:items-center mt-4">
            <div className="text-xs flex gap-2 items-center">
              <ArrowUp className="text-emerald-500 w-4 h-4" />
              <p>
                <span className="text-emerald-500">+6.5%</span> Sincelast month
              </p>
            </div>
            <div className="flex items-end">
              <p className="text-blue-500 italic font-medium whitespace-nowrap text-xs">View more...</p>
            </div>
          </div> */}
        </div>
        {/* Five */}
        <div className="border rounded-xl shadow-lg p-4">
          <div className="flex justify-between gap-2">
            <div className="w-full flex gap-2 xl:gap-4">
              <div className="w-1 h-full bg-rose-500 rounded-full"></div>
              <div>
                <h1 className="font-semibold mb-1 text-sm xl:text-base">
                  Wallet Recharge
                </h1>
                <div className="text-sm xl:text-base">
                  {!isLoading ? (
                    data?.fundTransferred?.toLocaleString()
                  ) : (
                    <div className="w-20 h-5 bg-gray-200 rounded-xl animate-pulse"></div>
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <div className=" w-8 h-8 xl:w-11 xl:h-11 bg-rose-200 flex justify-center items-center rounded-lg p-1">
                <SmartphoneCharging className="w-full h-full text-rose-600" />
              </div>
            </div>
          </div>
          {/* <div className="text-xs flex gap-2 items-center mt-4">
            <ArrowUp className="text-emerald-500 w-4 h-4" />
            <p>
              <span className="text-emerald-500">+6.5%</span> Sincelast month
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default RetailerActivities;
