import React, { useState, useEffect, useRef } from "react";
import { Edit, LogOut, Power, User, UserRoundPlus, Wallet } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import ProfileModal from "../Modals/ProfileModal";
import BannerModal from "../Modals/BannerModal";

const Header = () => {
  const [logoutOpen, setLogoutOpen] = useState(false);
  const { userData, modal, setModal } = useAppContext();

  // console.log("userData", userData);

  const logoutRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setLogoutOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleLogout = () => {
    setLogoutOpen(!logoutOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <>
      {modal === "banner" && <BannerModal />}
      {modal === "profile" && <ProfileModal />}
      <div className="w-full bg-gradient-to-b from-[#00529D] to-bg-color flex justify-between items-center p-4">
        <div className="">
          <img
            loading="lazy"
            src="/images/logo.png"
            alt="Logo"
            width={55}
            height={55}
            className=""
          />
        </div>

        <div className="flex justify-center items-center gap-4 sm:gap-8">
          {userData?.role === "SUB-RETAILER" && (
            <Link to="/transfer-records" className="text-white">
              <span className="flex items-center justify-center gap-2 ">
                <Wallet />
                <p className="text-sm sm:text-base hidden sm:block">
                  {userData?.fundBalance
                    ? (userData?.fundBalance).toLocaleString()
                    : "00.00"}
                </p>
              </span>
              <p className="text-right text-sm mt-1">Balance</p>
            </Link>
          )}
          <div className="relative">
            <div
              onClick={toggleLogout}
              ref={logoutRef}
              className="flex justify-between items-center gap-2 cursor-pointer"
            >
              <div className=" p-0.5 rounded-full w-14 h-14 flex justify-center items-center bg-gray-100">
                {userData?.kyc?.userSelfie ? (
                  <img
                    width={50}
                    height={50}
                    loading="lazy"
                    src={userData?.kyc?.userSelfie}
                    alt="Logo"
                    className="rounded-full object-cover w-full h-full"
                  />
                ) : (
                  <User className="w-full h-full text-gray-400" />
                )}
              </div>
              <div className="w-0.5 bg-white rounded-full h-12"></div>
              <div className="text-white">
                <h1 className="font-semibold">{userData?.name}</h1>
                <p className="text-sm italic">ID: {userData?.referralCode}</p>
              </div>
            </div>
            {logoutOpen && (
              <div
                className="absolute bg-white p-1 top-16 z-50 right-0 rounded-xl border w-44 space-y-2 shadow-lg"
                ref={logoutRef}
              >
                <button
                  onClick={() => {
                    setModal("profile");
                    setLogoutOpen(false);
                  }}
                  className="flex items-center gap-2 p-2 text-sm sm:text-base w-full hover:bg-gray-100 rounded-lg"
                >
                  <Edit className="w-4 h-4" />
                  <p className="">Change Profile</p>
                </button>
                <button
                  onClick={handleLogout}
                  type="button"
                  className="flex items-center gap-2 p-2 text-sm sm:text-base w-full hover:bg-rose-100 rounded-lg text-rose-500"
                >
                  <LogOut className="w-4 h-4 " /> Logout
                </button>
              </div>
            )}
          </div>
          {/* <button
            onClick={handleLogout}
            className="text-white flex flex-col items-center"
          >
            <span className="p-1 bg-red-500 rounded-full">
              <Power className="w-6 h-6" />
            </span>
            <p className="text-sm ">Logout</p>
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Header;
