import { ImageUp, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { formatISODate } from "../../utils/formatTime";
import { useAppContext } from "../../context/AppContext";
import { getUser } from "../../services/auth";
import { editAdsBanner } from "../../services/manageAdsServices";
import { toast } from "react-toastify";
import DeleteAdsBannerModal from "../Modals/DeleteAdsBannerModal";

const EditAdvertiseForm = ({
  triggerFetch,
  selectedId,
  setEdit,
  setSelectedId,
}) => {
  const [formData, setFormData] = useState({
    advertisementId: "",
    title: "",
    url: "",
    startDate: "",
    // endDate: "",
    image: "",
  });
  
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState("");

  const { modal, setModal } = useAppContext();

  // Update form fields whenever `selectedId` changes
  useEffect(() => {
    if (selectedId) {
      setFormData({
        advertisementId: selectedId.id || "",
        title: selectedId.title || "",
        url: selectedId.url || "",
        startDate: formatISODate(selectedId.startDate) || "",
        // endDate: formatISODate(selectedId.endDate) || "",
        image: selectedId.image || "",
      });
    }
  }, [selectedId]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (validTypes.includes(file.type)) {
        setUploadedFile(file);
        setFormData({ ...formData, image: URL.createObjectURL(file) }); // Update the form data with the uploaded file URL
        setError("");
      } else {
        setError("Invalid file format. Only PNG, JPEG, and JPG are allowed.");
        setUploadedFile(null);
      }
    }
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
    setFormData({ ...formData, image: "" });
    setError("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      const user = await getUser();

      const formDateToSubmit = new FormData();

      formDateToSubmit.append("advertisementId", formData.advertisementId);
      formDateToSubmit.append("title", formData.title);
      formDateToSubmit.append("url", formData.url);
      formDateToSubmit.append("startDate", formData.startDate);
      // formDateToSubmit.append("endDate", formData.endDate);
      if (uploadedFile) {
        formDateToSubmit.append("image", uploadedFile); // Append the actual file, not the blob URL
      }

      const res = await editAdsBanner(user, formDateToSubmit);

      if (res.status) {
        toast.success(res.message);
        setSelectedId(null);
        setEdit("add");
        triggerFetch();
        console.log("res", res);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {modal === "delete" && (
        <DeleteAdsBannerModal
          triggerFetch={triggerFetch}
          advertisementId={selectedId.id}
          setEdit={setEdit}
          setSelectedId={setSelectedId}
        />
      )}
      <div>
        <form className="h-full" onSubmit={handleSubmitEdit}>
          <div className="flex flex-col sm:flex-row justify-between gap-4 w-full h-full">
            <div className="flex flex-col gap-6 w-full">
              <span className="space-y-1">
                <h1 className="text-sm">Add Title</h1>
                <input
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Enter Title"
                  type="text"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
              <span className="flex flex-col sm:flex-row gap-4 w-full">
                <span className="space-y-1 w-full">
                  <h1 className="text-sm">Start Date</h1>
                  <input
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    type="date"
                    required
                    className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                  />
                </span>
                {/* <span className="space-y-1 w-full">
                  <h1 className="text-sm">End Date</h1>
                  <input
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleChange}
                    type="date"
                    required
                    className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                  />
                </span> */}
              </span>
              <span className="space-y-1">
                <h1 className="text-sm">URL</h1>
                <input
                  name="url"
                  value={formData.url}
                  onChange={handleChange}
                  placeholder="Enter URL"
                  type="text"
                  required
                  className="border p-2 h-10 rounded-xl w-full outline-none text-sm"
                />
              </span>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <div className="relative w-full">
                <h1 className="text-sm mb-1">Advertisement Banner</h1>
                <input
                  type="file"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  id="fileInput"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="fileInput"
                  className="flex flex-col gap-2 items-center justify-center w-full h-32 border rounded-xl cursor-pointer"
                >
                  {uploadedFile || formData.image ? (
                    <img
                      src={
                        uploadedFile
                          ? URL.createObjectURL(uploadedFile)
                          : formData.image
                      }
                      alt="Upload Preview"
                      className="h-full w-full object-cover rounded-xl"
                    />
                  ) : (
                    <>
                      <ImageUp />
                      <p className="text-xs">Upload your banner</p>
                    </>
                  )}
                </label>
                <span className="flex justify-between items-center relative">
                  <p className="text-xs mt-1 text-neutral-400 text-right">
                    Max Size: 312 X 128
                  </p>
                  <p className="text-xs mt-1 text-neutral-400 text-right relative">
                    Format: PNG, JPG, JPEG
                  </p>
                </span>
                {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
                {uploadedFile && (
                  <div className="w-full relative bg-white h-10 rounded-lg flex justify-end items-center ">
                    <button
                      title="Delete"
                      onClick={handleDeleteFile}
                      className="p-1 bg-red-300 rounded-md hover:bg-opacity-85"
                    >
                      <Trash2 className="w-4 h-4 text-red-500" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="h-full mt-52 flex gap-4 justify-center items-center">
            <button
              type="submit"
              className="bg-custom-border w-56 p-2 rounded-xl text-white hover:bg-opacity-85"
            >
              Save
            </button>
            <button
              onClick={() => setModal("delete")}
              type="button"
              className="bg-red-300 hover:bg-opacity-85 w-56 p-2 rounded-xl flex justify-center items-center gap-2"
            >
              <Trash2 className="text-red-500" />
              Delete
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAdvertiseForm;
