import { ScreenShare } from "lucide-react";
import React from "react";
import { useAppContext } from "../../../context/AppContext";
import RetailerActivities from "./RetailerActivities";
import DonutChart from "./DonutChart";
import LineChart from "./LineChart";
import RetailerCommissionTable from "./RetailerCommissionTable";
import RetailerTransactionTable from "./RetailerTransactionTable";

const RetailerStats = () => {
  const { userData } = useAppContext();

  return (
    <>
      <div>
        {/* Messages */}
        <div className="flex justify-between items-start gap-4">
          <div className="flex items-center gap-3 mb-6">
            <div className="">
              <ScreenShare className="w-8 h-8 sm:w-10 sm:h-10 text-bg-color" />
            </div>
            <div>
              <h1 className="font-semibold text-md sm:text-xl">
                Data, Stats & Analytics
              </h1>
              <p className="text-xs sm:text-sm">
                Explore data, stats, and insights for our{" "}
                <span className="italic font-semibold ">{userData?.role}</span>.
              </p>
            </div>
          </div>
        </div>
        <RetailerActivities />
        <div className="mt-6 flex flex-col lg:flex-row justify-between gap-4 w-full h-full flex-grow">
          <div className="w-full lg:w-4/12 flex flex-col">
            <div className="flex-grow">
              <DonutChart />
            </div>
          </div>
          <div className="w-full lg:w-8/12 flex flex-col">
            <div className="flex-grow">
              <LineChart />
            </div>
          </div>
        </div>

        <div className="mt-6 bg-white rounded-xl p-4 border shadow-lg">
          <RetailerCommissionTable />
        </div>
        <div className="mt-6 bg-white rounded-xl p-4 border shadow-lg">
          <RetailerTransactionTable />
        </div>
      </div>
    </>
  );
};

export default RetailerStats;
