import React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { X } from "lucide-react";
import { useAppContext } from "../../context/AppContext";
import { renew } from "../../services/manageVipServices";
import { getUser } from "../../services/auth";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const VipRenewCheckoutModal = ({
  selected,
  setSelected,
  triggerFetch,
  checkoutDetails,
}) => {
  const { setModal } = useAppContext();

  const renewMutation = useMutation(
    async () => {
      const user = await getUser();
      return await renew(user, checkoutDetails._id);
    },
    {
      onSuccess: (data) => {
        if (data?.status) {
          // console.log(data);
          toast.success(data.message);
          setModal("");
          setSelected(null);
          triggerFetch();
        } else {
          console.warn(data);
          toast.error(data.message);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return (
    <>
      <ModalLayout maxWidth="max-w-md">
        <div className="">
          <div className="flex justify-between items-center p-4 border-b">
            <h1 className="text-lg font-semibold">Checkout</h1>
            <button
              onClick={() => setModal("")}
              className="hover:bg-gray-200 hover:text-red-600 p-1 rounded-full"
            >
              <X />
            </button>
          </div>

          <div className="p-4">
            <div className="flex flex-col justify-center items-center ">
              <div className="w-24 h-24 p-2 shadow-lg rounded-full">
                <img
                  src="/images/ex.png"
                  loading="lazy"
                  className="w-full h-full"
                />
              </div>
              <div className="mt-4 text-center">
                <p className="font-semibold text-lg">
                  {selected?.stockBalance?.toLocaleString()}
                </p>
                <p className="text-sm">Current Balance</p>
              </div>
            </div>

            <div className="p-2 border border-blue-500 rounded-xl mt-8 space-y-2 text-sm">
              <span className="flex justify-between items-center">
                <p>Subscription Price(₹)</p>
                <p>{(checkoutDetails?.price).toLocaleString()}</p>
              </span>
              <span className="font-semibold flex justify-between items-center">
                <p>Total Amount</p>
                <p>{(checkoutDetails?.price).toLocaleString()} </p>
              </span>
            </div>
            <p className="text-xs mt-2">
              *Amount will be deducted from your wallet.
            </p>

            <div className="flex justify-center items-center gap-4 mt-12 mb-2 text-sm">
              <button
                disabled={renewMutation.isLoading}
                onClick={() => renewMutation.mutate()}
                className={`bg-custom-border hover:bg-opacity-85 rounded-xl w-44 h-10 text-white disabled:cursor-not-allowed ${
                  renewMutation.isLoading ? "bg-gray-400" : ""
                }`}
              >
                {renewMutation.isLoading ? "Processing..." : "Pay"}
              </button>
              <button
                onClick={() => setModal("")}
                className="w-44 h-10 border border-custom-border rounded-xl hover:bg-black/5"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default VipRenewCheckoutModal;
