import { ScreenShare } from "lucide-react";
import React from "react";
import { useAppContext } from "../../../context/AppContext";
import CountryDonutChart from "./CountryDonutChart";
import CountryLineChart from "./CountryLineChart";
import CountryCommissionTable from "./CountryCommissionTable";
import CountryActivities from "./CountryActivities";
import CountryTransactionTable from "./CountryTransactionTable";

const CountryStats = () => {
  const { userData } = useAppContext();
  return (
    <>
      <div>
        {/* Messages */}
        <div className="flex items-center gap-3 mb-6">
          <div className="">
            <ScreenShare className="w-8 h-8 sm:w-10 sm:h-10 text-bg-color" />
          </div>
          <div>
            <h1 className="font-semibold text-md sm:text-xl">
              Data, Stats & Analytics
            </h1>
            <p className="text-xs sm:text-sm">
              Explore data, stats, and insights for our{" "}
              <span className="italic font-semibold ">{userData?.role}</span>.
            </p>
          </div>
        </div>
        <CountryActivities />
        <div className="mt-6 flex flex-col lg:flex-row justify-between gap-4 w-full h-full flex-grow">
          <div className="w-full lg:w-4/12 flex flex-col">
            <div className="flex-grow">
              <CountryDonutChart />
            </div>
          </div>
          <div className="w-full lg:w-8/12 flex flex-col">
            <div className="flex-grow">
              <CountryLineChart />
            </div>
          </div>
        </div>

        <div className="mt-6 bg-white rounded-xl p-4 border shadow-lg">
          <CountryCommissionTable />
        </div>
        <div className="mt-6 bg-white rounded-xl p-4 border shadow-lg">
          <CountryTransactionTable />
        </div>
      </div>
    </>
  );
};

export default CountryStats;
