import React, { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { useQuery } from "react-query";
import { fetchOfferBenefitsForSubRetailers } from "../../services/manageOfferServices";
import { getUser } from "../../services/auth";
import { Link } from "react-router-dom";

const OfferBenefitBanners = ({offerBanners}) => {
  const [sideChange, setSideChange] = useState(0);
   const swiperRef = useRef(null);
   
  return (
    <div className="w-full h-full">
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={5} // Set gap between slides
        slidesPerView={1} // Default: 1 image per screen
        breakpoints={{
          1024: {
            // For screens >= 1024px (lg)
            slidesPerView: 2, // Show 2 images per screen
            spaceBetween: 10, // More gap between images
          },
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => setSideChange(swiper.activeIndex)}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        className="relative w-full h-full rounded-xl"
        loop
      >
        {offerBanners?.docs?.length > 0 &&
          offerBanners?.docs?.map((banner, id) => (
            <SwiperSlide key={id} className="relative">
              <Link
                to={banner?.url}
                target="_blank"
                className="w-full h-full flex justify-center items-center "
              >
                <img
                  src={banner?.image}
                  className="w-full h-full object-fill rounded-xl"
                  alt="Offer Banner"
                />
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default OfferBenefitBanners;
