import { ChevronDown } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { fetchPackagePrice } from "../../../services/manageAdsServices";
import { getUser } from "../../../services/auth";
import { useAppContext } from "../../../context/AppContext";

const AdsPackageSelector = ({ selectedOption, setSelectedOption }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { userData } = useAppContext();
  const dropdownRef = useRef(null);

  const { data: packageDetails, isLoading } = useQuery({
    queryKey: ["packageDetails"],
    queryFn: async () => {
      const user = await getUser();
      const res = await fetchPackagePrice(user);
      return res.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (packageDetails?.docs?.length > 0) {
      setSelectedOption(packageDetails.docs[0]);
    }
  }, [packageDetails]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative h-auto w-full">
      <button
        type="button"
        title="option"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex w-full gap-4 justify-between items-center border hover:border-blue-500 px-2 py-1.5 rounded-xl text-sm sm:text-base"
      >
        {selectedOption
          ? `${selectedOption.months} months - ${selectedOption.price} ${userData?.country?.currencyCode}`
          : "Select Package"}
        <ChevronDown className="w-5 h-5" />
      </button>

      {isDropdownOpen && (
        <div
          className="absolute right-0 border p-2 rounded-xl mt-2 w-full min-w-36 z-50 bg-white space-y-1 shadow-lg text-sm sm:text-base"
          ref={dropdownRef}
        >
          {packageDetails?.docs?.map((option) => (
            <div
              key={option._id}
              className={`p-2 cursor-pointer rounded-lg transition-all duration-150 ${
                selectedOption?._id === option._id
                  ? "bg-blue-500 text-white"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                setSelectedOption(option);
                setIsDropdownOpen(false);
              }}
            >
              {option.months} months - {option.price}{" "}
              {userData?.country?.currencyCode}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdsPackageSelector;
