import React from "react";
import { Trash2 } from "lucide-react";
import { Switch } from "@headlessui/react";
import { formatDateMonthYear } from "../../utils/formatTime";
import { getUser } from "../../services/auth";
import {
  disableOfferBenefit,
  enableOfferBenefit,
} from "../../services/manageOfferServices";
import { toast } from "react-toastify";

const ManageOfferBenefitTable = ({
  setModal,
  offerBenefits,
  currentPage,
  limit,
  setOfferId,
  triggerRefetch,
}) => {
  // console.log("offerBenefits", offerBenefits?.docs);

  const openModal = (id) => {
    setModal("delete");
    setOfferId(id);
  };

  // For enable and disable offers benefits
  const handleAction = async (action, id) => {
    try {
      const user = await getUser();
      const res = action
        ? await disableOfferBenefit(user, id)
        : await enableOfferBenefit(user, id);

      if (res.status) {
        toast.success(res.message);
        triggerRefetch();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="mt-2">
        <div className="flex flex-col">
          <div className="overflow-x-auto ">
            <div className="inline-block min-w-full">
              <div className="overflow-hidden rounded-lg border border-custom-border">
                <table className="w-full">
                  <thead className="bg-sub-card border-b border-custom-border bg-gradient-to-t from-custom-border to-bg-color text-white">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Serial No.
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Image
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        View Count
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Pop-Up Date
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        End Date
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-sm font-semibold uppercase tracking-wider whitespace-nowrap"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="overflow-hidden">
                    {offerBenefits?.docs?.map((offer, id) => (
                      <tr
                        key={id}
                        className={`${
                          id % 2 === 0 ? "bg-white" : "bg-bg-color/10"
                        }`}
                      >
                        <td className="p-4 whitespace-nowrap text-sm">
                          {id + 1 + (currentPage - 1) * limit}.
                        </td>
                        <td className="p-4 whitespace-nowrap text-sm">
                          {offer?.title}
                        </td>
                        <td className="p-4 whitespace-nowrap text-sm ">
                          <div className="w-44 h-16 rounded-md">
                            <img
                              src={offer?.image}
                              alt="banner"
                              loading="lazy"
                              className="w-full h-full object-fill rounded-md"
                            />
                          </div>
                        </td>
                        <td className="p-4 whitespace-nowrap text-sm">
                          {offer?.viewCount}
                        </td>
                        <td className="p-4 whitespace-nowrap text-sm">
                          {formatDateMonthYear(offer?.startDate)}
                        </td>
                        <td className="p-4 whitespace-nowrap text-sm">
                          {formatDateMonthYear(offer?.endDate)}
                        </td>
                        <td className="p-4 whitespace-nowrap text-sm">
                          <span className="flex justify-start items-center gap-4">
                            <button
                              onClick={() => openModal(offer?.id)}
                              className="flex items-center gap-2"
                            >
                              <Trash2 className="w-5 h-5" stroke="red" />
                            </button>
                            <Switch
                              checked={offer?.isActive}
                              onChange={() =>
                                handleAction(offer?.isActive, offer?.id)
                              }
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-custom-border"
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className="pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                              />
                            </Switch>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageOfferBenefitTable;
